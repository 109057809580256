import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  logo: {
    flexGrow: "1",
  },
  reportBtn: {
    textTransform: "none",
  },
}));
const Navigation = () => {
  const code = process.env.REACT_APP_AZURE_API_CODE;
  const { isAuthenticated, loginWithRedirect, logout,user } = useAuth0();
  console.log(isAuthenticated);
  const classes = useStyles();
  const [isMasterClient, setMasterClient] = useState(false);
  useEffect(() => {
    if(user !== undefined){

      axios
        .get("GetSubClients", {
          params: { code, domain: user.email.split("@")[1] },
        })
        .then(({ data }) => {
          console.log(data);
          if (data.length !== 0) {
            setMasterClient(true);
          } 
      
        });
    }
   
      
    // eslint-disable-next-line
  }, []);
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Box className={classes.logo} pb={2}>
              <Typography variant="h5" color="primary">
                {process.env.REACT_APP_TITLE}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={3}>
              {isAuthenticated && (
                <React.Fragment>
                  <Box mr={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<ListIcon />}
                      component={Link}
                      to="search-line"
                    >
                      Search
                    </Button>
                  </Box>
                  <Box mr={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.reportBtn}
                      startIcon={<ListIcon />}
                      component={Link}
                      to="lines-data-usage"
                    >
                      Lines Data Usage
                    </Button>
                  </Box>
                  {isMasterClient && ( <Box mr={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<ListIcon />}
                      component={Link}
                      to="assign-lines"
                    >
                      Assign Lines
                    </Button>
                  </Box>)}
                 
                </React.Fragment>
              )}

              <Box mr={1}>
                {!isAuthenticated ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.reportBtn}
                    startIcon={<PermIdentityIcon />}
                    onClick={
                      () => loginWithRedirect()
                      // {redirectUri:`${window.location.origin}/devices`}
                    }
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.reportBtn}
                    startIcon={<ExitToAppIcon />}
                    onClick={() =>
                      logout({
                        returnTo: window.location.origin,
                      })
                    }
                  >
                    Logout
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
