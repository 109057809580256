import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
const hist = createBrowserHistory();
//process.env.NODE_ENV = 'prod';


axios.defaults.baseURL = process.env.REACT_APP_AZURE_BASE_URL;

// axios.defaults.params['code'] = 'Y1nJUbtRfXDDoPe2MaUpsQiOPrTyXiPU0sf7sEvSFNdI1E/JLQDd/A=='
axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

ReactDOM.render(
  <Router history={hist}>
  <Auth0ProviderWithHistory>
    <Switch>
      <Route component = {App} />
    </Switch>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
