import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Grid } from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";

export default function SimChangeComponent(props) {
  const code = process.env.REACT_APP_AZURE_API_CODE;
  const { lineId, setLoading } = props;
  const [newSim, setNewSim] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  let checkSimInterval;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkSimChangeStatus = (id) =>{
    setLoading(true);
    axios
      .get("GetTMOSimChangeStatus", {
        params: { code, id: id },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.status === 'Completed') {
          setMessage({ type: "success", text: data.status });
          setLoading(false);
          clearInterval(checkSimInterval);
        }else if(data.status === 'Failed'){
          setMessage({ type: "error", text: data.error });
          setLoading(false);
          clearInterval(checkSimInterval);
        }
       
      });
  }
  const tmoLineChange = () => {
    setLoading(true);
    axios
      .get("SimSwapTMO", {
        params: { code, line_id: lineId, new_sim: newSim },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.id !== undefined) {
          setMessage({ type: "success", text: data.message });
          checkSimInterval = setInterval(checkSimChangeStatus,10000,data.id);
        } 
        
      })
      .catch((error) => {
        setMessage({ type: "error", text: error.response.data });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Box pb={1}>
        <Grid container direction="row" justify="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            SIM Change
          </Button>
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Change SIM for {lineId}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          <DialogContentText>
          Please enter new sim number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Sim"
            fullWidth
            value={newSim}
            onChange={(e) => setNewSim(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={tmoLineChange} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
