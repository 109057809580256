import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Loader";
import axios from "axios";
import { Typography, Box, Button, Container, Grid,Chip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Alert from "@material-ui/lab/Alert";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import moment from "moment";

const LinesWithDataUsage = () => {
  const code = process.env.REACT_APP_AZURE_API_CODE;
  const { user } = useAuth0();
  const email_domain = user.email.split("@")[1];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lines, setLines] = useState([]);
  const [exportData, setExportData] = useState([]);
  const exportDataLink = useRef();
  const [sortModel, setSortModel] = React.useState([
    { field: "data_used", sort: "desc" },
  ]);

  const columns = useRef([
    { field: "iccid", headerName: "Iccid", flex: 1 },
    {
      field: "last_updated_iccid",
      headerName: "Last Updated ICCID",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_iccid &&
        parseInt(moment(params.row.last_updated_iccid).format("x")),
      renderCell: (params) =>
        params.row.last_updated_iccid && (
          <Typography>
            {moment.utc(params.row.last_updated_iccid).local().format("lll")}
          </Typography>
        ),
    },
    { field: "msisdn", headerName: "MSISDN", flex: 1,
      renderCell: (params) =>
          <React.Fragment>
          {params.row.msisdn && (
            <Typography>
              {params.row.msisdn} &nbsp;
            </Typography>
          
          )}
          {params.row.has_static_ip && (
            <Chip color="primary"  label="Static IP"></Chip>
          )}
          </React.Fragment>
          ,
    // 

   },
    {
      field: "data_used",
      headerName: "Data Used",
      flex: 1,
      renderCell: (params) => params.row.data_used,
      valueGetter: (params) => {
        let data_used = params.row.data_used;
        if (typeof data_used === "string" && data_used.includes("GB")) {
          let converted =
            parseFloat(data_used.replace("GB", "").trim()) * 1024 * 1024;
          return isNaN(converted) ? -1 : converted;
        } else if (typeof data_used === "string" && data_used.includes("MB")) {
          let converted = parseFloat(data_used.replace("MB", "").trim()) * 1024;
          return isNaN(converted) ? -1 : converted;
        }
        return -1;
      },
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated &&
        parseInt(moment(params.row.last_updated).format("x")),
      renderCell: (params) =>
        params.row.last_updated && (
          <Typography>
            {moment.utc(params.row.last_updated).local().format("lll")}
          </Typography>
        ),
    },
    {
      field: "billing_cycle",
      headerName: "Usage Cycle / End Date",
      flex: 1,
      valueGetter: (params) => {
        if (
          params.row.billing_cycle &&
          params.row.billing_cycle.trim() !== "" &&
          params.row.billing_cycle.trim().includes("T")
        ) {
          return moment(
            params.row.billing_cycle.trim().split("T")[0],
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return params.row.billing_cycle;
      },
    },
  ]);

  if (email_domain === process.env.REACT_APP_MASTER_EMAIL) {
    // Access reference value:
    const newValue = columns.current;
    newValue.push({ field: "client", headerName: "Client", flex: 1 });

    // Update reference value:
    columns.current = newValue;
  }

  const convertDataUsage = (data_usage) => {
    if (data_usage.includes('MB')) {
      return `${(parseFloat(data_usage.replace('MB', '').trim()) / 1024).toFixed(2)} GB`;
    }

    return data_usage;
  }
  useEffect(() => {
    setLoading(true);

    axios
      .get("GetLines", {
        params: { code, client_id: email_domain },
      })
      .then(({ data }) => {
        let exportDataModified = [];
        for (let line of data) {
          exportDataModified.push({
            iccid: `'${line.iccid}`,
            last_updated_iccid: line.last_updated_iccid,
            msisdn: line.msisdn,
            data_used: convertDataUsage(line.data_used),
            billing_cycle: line.billing_cycle,
            last_updated_data_usage: line.last_updated,
            has_static_ip:line.has_static_ip ? 'Yes' : 'No'

          });
        }



        setExportData(exportDataModified);
        console.log(data);
        let modifiedData = [];
        if (data.length !== 0) {
          console.log(data[0].hasOwnProperty('sub_client'));
          if(data[0].hasOwnProperty('sub_client')){
            // Access reference value:
            const newValue2 = columns.current;
            newValue2.push({ field: "sub_client", headerName: "Sub Client", flex: 1 });
            columns.current = newValue2;
          }


        }
        for (let line of data) {
          modifiedData.push({
            ...line,
            data_used: convertDataUsage(line.data_used),
          });

        }
        setLines(modifiedData);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container maxWidth="xl">
        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}
        <Box pt={2}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => {
                  exportDataLink.current.link.click();
                }}
              >
                Export
              </Button>
              <CSVLink
                data={exportData}
                // headers={export_headers}
                filename={`lines_data_usage_${moment().format(
                  "YYYYMMDD_hmmss"
                )}.csv`}
                ref={exportDataLink}
              />
            </Grid>
          </Grid>
        </Box>
        {lines.length !==0 && (<Box pt={2}>
          <DataGrid
            rows={lines}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </Box>)}
        
      </Container>
    </React.Fragment>
  );
};

export default LinesWithDataUsage;
