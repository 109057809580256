import React, { useState, useEffect } from "react";
import "../styles/customer-journey.css";
import Loader from "./Loader";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Chip
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useAuth0 } from "@auth0/auth0-react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import SimChangeComponent from "./SimChange";

const SearchLine = (props) => {
  const code = process.env.REACT_APP_AZURE_API_CODE;

  const [loading, setLoading] = useState(false);
  const [lineId, setLineId] = useState("");
  const [lineData, setLineData] = useState(null);
  const [usageData, setUsageData] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [getDataUsage, setGetDataUsage] = useState(true);
  const { user } = useAuth0();
  const email_domain = user.email.split("@")[1];

  // let history = useHistory();
  let location = useLocation();

  const getUsageData = (msisdn) => {
    setLoading(true);
    axios
      .get("GetUsageDetails", {
        params: { code, msisdn },
      })
      .then(({ data }) => {
        console.log(data);
        setUsageData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  };

  const searchLineWithId = (line_id) => {
    setLoading(true);
    axios
      .get("GetLineDetails", {
        params: { code, line_id,domain:email_domain },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.error) {
          setError(data.error[0].userMessage);
          setLoading(false);
        } else {
          setLineData(data);
          setError(undefined);
          setLoading(false);
          if (getDataUsage && data.sim_type === "VZN" ){
            setUsageData(data.usageData);
          } else if (getDataUsage && data.sim_type !== "ATT" ) {
            getUsageData(data.msisdn);
          } else {
            setUsageData(data.usageData);
          }
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLineData(undefined);
        setUsageData(undefined);
        setLoading(false);
      });
  };
  const searchLine = () => {
    if (lineId !== "") {
      // history.push({
      //   search: `?${queryString.stringify({ lineId })}`,
      // });
      searchLineWithId(lineId);
    }
  };
  const clearSearch = () => {
    setLineId("");
    // history.push({
    //   search: ``,
    // });
  };

  const attLineRefresh = () => {
    setLoading(true);
    axios
      .get("LineRefresh", {
        params: { code, line_id: lineId },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.status !== undefined) {
          setSuccessMessage(data.status);
          setError(undefined);
        } else {
          setError(data.error);
          setSuccessMessage(undefined);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    // searchDeviceWithSearchParams({});
    const filterValues = queryString.parse(location.search);
    console.log(filterValues);
    if (filterValues.lineId) {
      setLineId(filterValues.lineId);
      searchLineWithId(filterValues.lineId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Box mb={2} pt={2}>
          <Typography variant="h5" align="left">
            {props.title}
          </Typography>
        </Box>
        <Box mb={2} pt={2}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={4}
            alignItems="center"
          >
            <Grid item xs={12} lg={4}>
              <TextField
                id="line_id"
                label="Line ID (SIM or MSISDN)"
                variant="outlined"
                fullWidth
                value={lineId}
                onChange={(e) => setLineId(e.target.value)}
                size="small"
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={2}
              style={{ textAlign: "left", padding: "0 0 0 16px" }}
              gutterBottom={false}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getDataUsage}
                    onChange={(e) => setGetDataUsage(e.target.checked)}
                    size="small"
                  />
                }
                label="Get Data Usage"
              />
            </Grid>

            <Grid item>
              <Button variant="contained" color="primary" onClick={searchLine}>
                Search Line
              </Button>
            </Grid>

            <Grid item>
              <Button variant="outlined" color="primary" onClick={clearSearch}>
                Clear Search
              </Button>
            </Grid>
          </Grid>
        </Box>

        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}

        {successMessage && (
          <Box pt={3}>
            <Alert severity="success"> {successMessage} </Alert>
          </Box>
        )}

        {lineData && lineData.sim_type === "ATT" && (
          <Box pb={1}>
            <Grid container direction="row" justify="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={attLineRefresh}
              >
                Refresh Line
              </Button>
            </Grid>
          </Box>
        )}

        {lineData && lineData.sim_type === "TMOBILE" && (
          <SimChangeComponent lineId={lineId} setLoading={setLoading} />
        )}

        {lineData && (
          <TableContainer component={Paper}>
            <Table size="small" style={{ tableLayout: "fixed" }}>
              <TableBody>
                <TableRow>
                  <TableCell>MSISDN</TableCell>
                  <TableCell>{lineData.msisdn} {lineData.has_static_ip && <Chip color="primary"  label="Static IP"></Chip>}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{lineData.status}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>SIM Number</TableCell>
                  <TableCell>{lineData.sim}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>IMEI</TableCell>
                  <TableCell>{lineData.imei}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Handset Manufacturer</TableCell>
                  <TableCell>{lineData.manufacturerName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Carrier</TableCell>
                  <TableCell>{lineData.carrier}</TableCell>
                </TableRow>
                {lineData.product_type && (
                  <TableRow>
                    <TableCell>Product Type</TableCell>
                    <TableCell>{lineData.product_type}</TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
        )}

        {usageData && lineData && (
          <React.Fragment>
            <Box mb={2} pt={2}>
              <Typography variant="h5" align="left">
                Usage Data
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableBody>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>
                      {usageData.dataUsed}{" "}
                      {lineData.sim_type === "ATT" || lineData.sim_type === "VZN" ? "" : "MB"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Text</TableCell>
                    <TableCell>{usageData.textSent}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Voice</TableCell>
                    <TableCell>{usageData.voiceUsed} minutes</TableCell>
                  </TableRow>

                  {usageData.billCycleCloseDate && (
                    <TableRow>
                      <TableCell>Usage Cycle End Date</TableCell>
                      <TableCell>
                        {usageData.billCycleCloseDate.split("T")[0]}
                      </TableCell>
                    </TableRow>
                  )}
                  {usageData.billingCycle && (
                    <TableRow>
                      <TableCell>Usage Cycle</TableCell>
                      <TableCell>{usageData.billingCycle}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

SearchLine.defaultProps = {
  title: "Search Line",
};

export default SearchLine;
