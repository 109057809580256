import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Loader";
import axios from "axios";
import { Typography, Box, Button, Container, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

const AssignLines = () => {
    const code = process.env.REACT_APP_AZURE_API_CODE;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [subClients, setSubclients] = useState([]);
    const [subClient, setSubClient] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const { user } = useAuth0();
    const email_domain = user.email.split("@")[1];
    const [icccids, setIccids] = useState(null);
    useEffect(() => {
        setLoading(true);

        axios
            .get("GetSubClients", {
                params: { code, domain: email_domain },
            })
            .then(({ data }) => {
                console.log(data);

                setSubclients(data);
                setLoading(false);

            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const assignLine = () => {
        console.log(icccids);
        if (icccids === '') {
            alert('Enter ICCID(s).')
            return;
        }

        if (subClient === null || subClient === '') {
            alert('Select Sub-Client.')
            return;
        }
        console.log(subClient);
        let iccid_list = icccids.split('\n');
        console.log(iccid_list);

        setLoading(true);
        let data = { sub_client_domain: subClient, client_domain: email_domain, lines: iccid_list };
        axios
            .post("AssignLines", data, {
                params: { code, domain: email_domain },
            })
            .then(({ data }) => {
                console.log(data);
                setSuccessMessage(data.message);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
                setLoading(false);
            });
    }
    return (
        <React.Fragment>
            {loading && <Loader />}
            <Container >
                <Typography variant="h4" align="left">Assign Lines</Typography>

                {error && (
                    <Box pt={3}>
                        <Alert severity="error"> {error} </Alert>
                    </Box>
                )}

                {successMessage && (
                    <Box pt={3}>
                        <Alert severity="success"> {successMessage} </Alert>
                    </Box>
                )}

                <Box pt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <TextField
                                id="line_id"
                                label="ICCID(s) Or MSISDN(s)"
                                variant="outlined"
                                fullWidth
                                value={icccids}
                                onChange={(e) => setIccids(e.target.value)}
                                size="small"
                                multiline="true"
                                minRows={4}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel id="demo-simple-select-outlined-label">Select Client</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={subClient}
                                    onChange={(e) => {
                                        setSubClient(e.target.value);
                                    }}
                                    label="Sub Clients"

                                >
                                    <MenuItem value="" disabled>
                                        <em>Select Sub Clients</em>
                                    </MenuItem>
                                    {subClients && (subClients.map((client, i) => {
                                        return (
                                            <MenuItem value={client.domain}>{client.name} ({client.domain})</MenuItem>
                                        );
                                    }))}




                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" color="primary" onClick={assignLine}>
                                Assign Lines
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </React.Fragment>);

}


export default AssignLines;